* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  background: radial-gradient(#fda, #fa0);
  min-height: 100vh;
  width: 100%;
}

body {
  display: flex;
  flex-flow: column nowrap;
  padding: 1em;
}

@media (min-width: 600px) {
  body {
    justify-content: center;
  }
}

main {
  max-width: 400px;
  margin: 2em auto;
  padding: 2em;
  background-color: #feca;
  color: #000;
  border-radius: 3px;
  box-shadow: 1px 4px 6px #0003;
}

article {
  font-family: sans-serif;
  min-height: 200px;
  font-size: 1em;
  line-height: 1.5em;
  margin-bottom: 1em;
}

h2 {
  font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
  font-weight: normal;
  font-size: 2em;
  margin: 0;
  margin-bottom: 1em;
  padding-bottom: 0.25em;
  border-bottom: 1px solid #000;
}

button {
  float: right;
}
